<template>
	<div class="header">
		<div class="header-container">
			<div class="header-left">
				<img src="../../assets/icons/phone.png" />
				<p>0912-8496550</p>
			</div>
			<div class="header-right">
				<!-- <div class="language"></div> -->

				<div class="operate">
					<p class="simple">简&nbsp;/</p>
					<p class="english">&nbsp;EN</p>
					<img src="../../assets/icons/account.png" />
					<div class="login" v-if="!userInfo">
						<p @click="goView('/home')">登录</p>
						/
						<p class="enroll" @click="goView('/register')">注册</p>
					</div>
					<div class="login" v-else>
						<p>{{ userInfo.nickname }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			// userInfo: {
			//   default: Object,
			//   type: Object,
			// },
		},
		data() {
			return {
				userInfo: {},
			};
		},
		mounted() {},
		watch: {
			userInfo(val) {
				this.userInfo = val;
			},
		},
		methods: {
			goView(path) {
				this.$router.push({ path: path });
			},
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
			// console.log("userInfo.nickname", this.userInfo.nickname);
		},
	};
</script>
<style scoped lang="scss">
	@media (max-width: 480px) {
		.header {
			display: none;
		}
	}
	.header {
		z-index: 11;
		width: 100%;
		height: 50px;
		background-color: #222e3c;

		.header-container {
			width: 85.4%;
			height: 50px;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.header-left {
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					display: inline-block;
					margin-right: 5px;
					width: 24px;
					height: 24px;
				}

				p {
					display: inline-block;
					font-size: 24px;
					font-weight: 500;
					color: #ffffff;
				}
			}

			.header-right {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				.operate {
					cursor: pointer;
					color: #ffffff;
					font-size: 18px;
					font-weight: 400;
					letter-spacing: 4px;
					display: flex;
					align-items: center;

					.simple {
						color: #ffffff;
						font-size: 18px;
						font-weight: 400;
					}

					.login {
						display: flex;
						align-items: center;
						color: #ffffff;
						font-size: 18px;
					}

					.english {
						color: #ffffff;
						letter-spacing: 0px;
						font-size: 18px;
						font-weight: 400;
						opacity: 0.5;
						margin-right: 30px;
					}

					img {
						display: inline-block;
						margin-right: 5px;
						width: 24px;
						height: 24px;
					}
				}
			}
		}
	}
</style>
